import * as React from "react"
import PropTypes from "prop-types"

import logo from "../images/logo-tag.svg"
import { ArrowDownIcon } from "@heroicons/react/solid"

const Header = ({ siteTitle }) => (
  <header className="p-4 flex flex-col items-center justify-center h-screen bg-gradient-radial to-beige-500 from-white max-h-[800px] relative">
    <img
      alt="Legal Op logo"
      height="160"
      width="640"
      src={logo}
      className="w-full h-auto max-w-lg"
    />
    <p className="max-w-lg mt-8 text-lg text-center lg:text-xl text-brown">
      With just a few keystrokes, track, find and access your contracts –
      anytime, anywhere.
    </p>
    <div className="absolute bottom-0 pb-8">
      <a
        href="#services"
        className="block transition-transform transform scale-100 hover:scale-110"
      >
        <ArrowDownIcon className="w-16 h-16 text-blue" />
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
